import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Text, Link } from 'src/components/Common';
import { only } from 'styled-breakpoints';
import Fade from 'react-reveal/Fade';
import { useI18next } from 'gatsby-plugin-react-i18next';

const IconWrapper = styled.div`
  width: 110px;
  height: 110px;
  transition: all 1s;

  ${only('mini')} {
    width: 90px;
    height: 90px;
  };

  ${only('mobile')} {
    width: 100px;
    height: 100px;
  };

  &:hover {
    transform: scale(1.05);
  }
`;

const Root = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  width: 49%;
  height: 100%;

  ${props => props.position === 'first' && props.language === 'en' && css`
    border-right: 1px solid var(--secondary);

    ${only('mini')} {
      border-right: none;
    };
  `};

  ${props => props.position === 'first' && props.language === 'he' && css`
    border-left: 1px solid var(--secondary);

    ${only('mini')} {
      border-left: none;
    };
  `};

  ${only('mini')} {
    width: 100%;
    padding: 0;
    margin-bottom: 80px;
  };

  &:hover { 
    ${IconWrapper} {
      transform: scale(1.1);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 25px;
  color: var(--black);
`;

const Header = styled(Text)`
  height: ${props => `${props.height}px`};
  white-space: pre-line;

  ${only('mini')} {
    height: auto;
  };
`;

const ServiceBox = ({ header, content, image, position, headerHeight, delay, Icon, link }) => {
  const [hover, setHover] = useState(false);
  const { language } = useI18next();

  const handleHover = () => {
    setHover((prevHover) => !prevHover);
  };

  return (
    <Root position={position} language={language} onMouseEnter={handleHover} onMouseLeave={handleHover}>
      <Link to={link}>
        <Fade delay={delay}>
          <Content>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <Header
              height={headerHeight}
              id="ServiceBoxHeader"
              text={header}
              color="primary"
              variant="h4"
              isBold
              withAnimation
              activeAnimation={hover}
            />
            <Text text={content} variant="body1" />
          </Content>
        </Fade>
      </Link>
    </Root>
  );
}

export default ServiceBox;
