import * as React from "react";
import { graphql } from 'gatsby';
import Services from '../../components/Services';

const ServicesPage = () => <Services />;

export default ServicesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;