import * as React from "react"
import styled from "styled-components";
import ServicesSoldier from '/src/assets/soldiers/services_soldier_on_laptop.svg';
import TechSection from './TechSection';
import ServicesSection from './ServicesSection';
import { SEO, TopSection } from '../Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocation } from "@reach/router"

const Root = styled.main`
`;

const Services = () => {
  const { t } = useTranslation();
  const { href } = useLocation();

  const breadcrumbsElementsSchema = [{
    "@type": "ListItem",
    "position": 2,
    "name": t("Services"),
    "item": href
  }];

  return (
    <Root>
      <SEO
        title="SEO title services"
        description="SEO title description"
        breadcrumbsElementsSchema={breadcrumbsElementsSchema}
      />
      <TopSection
        header="All the services is one place"
        description="All the services description"
        Image={ServicesSoldier}
        size="large"
      />
      <TechSection />
      <ServicesSection />
    </Root>
  );
};

export default Services;
