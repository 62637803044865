import React from "react";
import styled from "styled-components";
import { Text, MiddleLayout } from 'src/components/Common';
import { only } from 'styled-breakpoints';
import { COLORS } from 'src/style/colors';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Wrapper = styled.div`
  padding: 60px 0 220px 0;

  ${only('mini')} {
    padding: 70px 0 50px 0;
  };
`;

const Root = styled.div`
  width: 100vw;
  height: 400px;
  background-image: ${props => `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  ${only('mini')} {
    height: 340px;
  };

  ${only('mobile')} {
    height: 340px;
  };
`;

const Content = styled(MiddleLayout)`
  display: flex;
  gap: 120px;
  align-items: center;
  height: 100%;

  ${only('mini')} {
    gap: 10px;
  };
  

  ${only('mobile')} {
    gap: 20px;
  };

  ${only('laptop')} {
    gap: 80px;
  };
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  text-align: initial;
  gap: 10px;
  width: 50%;

  ${only('mini')} {
    width: 100%;
  };

  ${only('mobile')} {
    width: 70%;
  };

  ${only('laptop')} {
    width: 60%;
  };
`;

const SoldierWrapper = styled.div`
  width: 230px;
  transform: translateY(6px);
  align-self: flex-end;
  z-index: 1000;

  ${only('mini')} {
    position: absolute;
    width: 120px;
    top: -65px;
    left: 50%;
    transform: translateX(-50%);
  };

  ${only('mobile')} {
    width: 200px;
  };
`;

const CarouselItem = ({ backgroundImage, Soldier, header, description }) => {
  const { i18n } = useI18next();

  return (
    <Wrapper dir={i18n?.dir()}>
      <Root backgroundImage={backgroundImage}>
        <Content>
          <SoldierWrapper>
            <Soldier />
          </SoldierWrapper>
          <Texts>
            <Text text={header} variant="h3" color={COLORS.WHITE} isBold />
            <Text text={description} variant="body1" color={COLORS.WHITE} />
          </Texts>
        </Content>
      </Root>
    </Wrapper>
  )
};

export default CarouselItem;
