import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Text, MiddleLayout } from 'src/components/Common';
import { only } from 'styled-breakpoints';
import AbcIcon from '/src/assets/servicesIcons/abc_icon.svg';
import DiamondIcon from '/src/assets/servicesIcons/diamond_icon.svg';
import MazeIcon from '/src/assets/servicesIcons/maze_icon.svg';
import BulbIcon from '/src/assets/servicesIcons/mobile_icon.svg';
import ServiceBox from './ServiceBox';
import { URL } from 'src/url';

const Root = styled.section`
  margin-bottom: 100px;

  ${only('mini')} {
    margin-bottom: 0;
  } ;
`;

const MiddleLayoutStyled = styled(MiddleLayout)`
  display: flex;
  flex-wrap: wrap;

  ${only('mini')} {
    flex-direction: column;
    text-align: center;
  } ;
`;

const HeaderWrapper = styled.div`
  text-align: center;
  padding: 40px 10px;
  width: 100%;
  background: var(--linear);
  margin-bottom: 70px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;

  &:first-child {
    border-bottom: 1px solid var(--secondary);

    ${only('mini')} {
      border-bottom: none;
    }
  }

  ${only('mini')} {
    height: auto;
    flex-direction: column;
  } ;
`;

const ServicesSection = () => {
  const [headerHeight, setHeaderHeight] = useState(130);

  const onWindowResize = useCallback(() => {
    const headers = [...document.querySelectorAll('#ServiceBoxHeader')];
    const maxHeight = Math.max.apply(
      Math,
      headers.map(({ scrollHeight }) => scrollHeight)
    );
    setHeaderHeight(maxHeight);
  }, []);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return (
    <Root>
      <HeaderWrapper>
        <Text
          text="Our Special Forces"
          variant="h2"
          isBold
          color="white"
          animation={{ name: 'flip' }}
        />
      </HeaderWrapper>
      <MiddleLayoutStyled>
        <Row>
          <ServiceBox
            header="services page font end header"
            content="Front End Development Description"
            position="first"
            headerHeight={headerHeight}
            Icon={DiamondIcon}
            link={URL.FRONT_END_SERVICE}
          />
          <ServiceBox
            header="services page full stack header"
            content="Fullstack End to End Description"
            headerHeight={headerHeight}
            delay={150}
            Icon={AbcIcon}
            link={URL.FULL_STACK_SERVICE}
          />
        </Row>
        <Row>
          <ServiceBox
            header="SaaS Web Applications Consulting"
            content="SaaS Web Applications Consulting Description"
            position="first"
            headerHeight={headerHeight}
            delay={450}
            Icon={MazeIcon}
            link={URL.SAAS_CONSULTING_SERVICE}
          />
          <ServiceBox
            header="services page mobile header"
            content="Mobile Development Description"
            headerHeight={headerHeight}
            delay={300}
            Icon={BulbIcon}
            link={URL.MOBILE_SERVICE}
          />
        </Row>
      </MiddleLayoutStyled>
    </Root>
  );
};

export default ServicesSection;
